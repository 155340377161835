import {
    DefaultPalette,
    IButtonStyles,
    IStackItemStyles,
    IStackTokens,
    mergeStyles,
    PrimaryButton,
    Stack
} from "@fluentui/react";
import {IImageProps, Image, ImageFit} from "@fluentui/react/lib/Image";
import {FontIcon} from "@fluentui/react/lib/Icon";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {UIContext} from '../../helpers/uiContext';
import * as settings from "../../helpers/addinConfig";
import {checkIfFormInserted, getFeedbackForm} from "../../api/Message.js";
import {checkAttachments, checkEmail, checkSentiment} from "../../api/BestPractices.js";
import {useState} from "react";


export const stackButtonTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
};

export const stackTokens: IStackTokens = {
    childrenGap: 0
};

const imageProps: Partial<IImageProps> = {
    src: require("./../../../assets/wbt-logo-text-white.png"),
    imageFit: ImageFit.contain,
};

const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: '0 25px',
    color: '#FFFFFF'
});

const footerClass = mergeStyles({
    position: 'absolute',
    bottom: 0,
    margin: 0,
});

const stackIconStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',
        background: DefaultPalette.themePrimary,
        color: DefaultPalette.white,
        display: 'flex',
        height: 80,
        justifyContent: 'center',
        overflow: 'hidden',
        cursor: 'pointer',
        marginLeft: 0,
        selectors: {
            ':hover': {
                backgroundColor: '#0c1981'
            }
        }
    },
};

const stackPictureStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',
        background: DefaultPalette.themePrimary,
        color: DefaultPalette.white,
        display: 'flex',
        height: 50,
        justifyContent: 'center',
        overflow: 'hidden',
        padding: 15,
        cursor: 'pointer',
        selectors: {
            ':hover': {
                backgroundColor: '#0c1981'
            }
        }
    },
};

export const stackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',
        background: DefaultPalette.themePrimary,
        color: DefaultPalette.white,
        display: 'flex',
        justifyContent: 'center',
    },
};

export const buttonStyles: IButtonStyles = {
    root: {
        width: '100%'
    },
};

const Footer = () => {

    const {t,i18n} = useTranslation();
    const config = settings.getConfig();


    const insertFeedBackForm = () => {
        let mailboxItem = Office.context.mailbox.item;
        mailboxItem.saveAsync(function (asyncResult){
            insertFeedBackFormCallback(asyncResult.value);
        });
    }

    const insertFeedBackFormCallback = (messageId) => {
        checkIfFormInserted().then(isInserted => {
                if (!isInserted) {

                    getFeedbackForm(messageId,config, t, i18n)
                        .then(content => {
                            Office.context.mailbox.item.body.setSelectedDataAsync(
                                content,
                                {coercionType: Office.CoercionType.Html},
                                (error) => {
                                    if (error) {
                                        console.log(error);
                                    }
                                }
                            );
                        })
                        .catch(console.log("Error"));
                }
            }
        )
    }



    const showSettingDialog = () => {
        // Display settings dialog.
        let URI = require('urijs');
        let url = URI("dialog.html").absoluteTo(window.location).toString();
        /*if (config)
            // If the add-in has already been configured, pass the existing values
            // to the dialog.
            url = url + "?workBetterUrl=" + config.workBetterUrl + "&workBetterUsername=" + config.workBetterUsername + "&myLanguage=" + i18n.language
                + (preferences!= null ? "&sendForm=" + preferences.is_onsend_feedback + "&checkMail=" + preferences.is_onsend_check : "&sendForm=false&checkMail=false&openCred=true");
        }*/

        let dialogOptions = { width: 30, height: 80, displayInIframe: true };

        Office.context.ui.displayDialogAsync(url, dialogOptions, function(result) {
            let settingsDialog = result.value;
            //settingsDialog.addEventHandler(Microsoft.Office.WebExtension.EventType.DialogMessageReceived, receiveMessage);
            //settingsDialog.addEventHandler(Microsoft.Office.WebExtension.EventType.DialogEventReceived, dialogClosed);
        });
    }

    return(
        <UIContext.Consumer>
            {({toggleEmailWizard}) => (
        <footer className={footerClass}>
            {!Office.context.mailbox.item.displayReplyForm &&
            <Stack tokens={stackButtonTokens}>
                <Stack.Item grow styles={stackItemStyles}>
                    <PrimaryButton styles={buttonStyles} text={t('main.button_check_email')} onClick={() => checkEmail()}/>
                </Stack.Item>
                <Stack.Item grow styles={stackItemStyles}>
                    <PrimaryButton styles={buttonStyles} text={t('main.button_insert_feedback')}
                                   onClick={() => insertFeedBackForm()}/>
                </Stack.Item>

            </Stack>
            }

            <Stack horizontal tokens={stackTokens}>
                <Stack.Item align="center" styles={stackPictureStyles} onClick={toggleEmailWizard}>
                    <Image {...imageProps}/>
                </Stack.Item>
                <Stack.Item align="center" styles={stackIconStyles}>
                    <FontIcon aria-label="Settings" iconName="Settings" className={iconClass} onClick={() => showSettingDialog()}/>
                </Stack.Item>
            </Stack>
        </footer>
            )}
        </UIContext.Consumer>
    );
}
export default Footer;
