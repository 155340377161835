import {
    FocusZone,
    getTheme,
    ITheme,
    List,
    mergeStyleSets,
    PrimaryButton,
    Stack,
    values,
    Text,
    IStackTokens, DefaultButton
} from "@fluentui/react";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import UserClient from "../../api/UserClient.js";
import {UIContext} from '../../helpers/uiContext';

const theme: ITheme = getTheme();
const { palette, fonts } = theme;

const classNames = mergeStyleSets({
    listGridExample: {
        overflow: 'hidden',
        fontSize: 0,
        position: 'relative',
    },
    listGridExampleTile: {
        textAlign: 'center',
        outline: 'none',
        position: 'relative',
        float: 'left',
        background: palette.neutralLighter,
        selectors: {
            'focus:after': {
                content: '',
                position: 'absolute',
                left: 2,
                right: 2,
                top: 2,
                bottom: 2,
                boxSizing: 'border-box',
                border: `1px solid ${palette.white}`,
            },
        },
    },
    listGridExampleSizer: {
        paddingBottom: '100%',
    },
    listGridExamplePadder: {
        position: 'absolute',
        left: 2,
        top: 2,
        right: 2,
        bottom: 2,
    },
    listGridExampleLabel: {
        position: 'absolute',
        padding: 10,
        verticalAlign: 'middle',
        left: 0,
        minWidth: 0,
        fontSize: fonts.large.fontSize,
    },
});

const initFeedback = [{value:1,selected:false},{value:2,selected:false},{value:3,selected:false},{value:4,selected:false},{value:5,selected:false},{value:6,selected:false}]

const Feedback = () => {

    const [from, setFrom] = useState(Office.context.mailbox.item.from.displayName);
    const [items, setItems] = useState(initFeedback)
    const {t,i18n} = useTranslation();
    const {itemChanged} = React.useContext(UIContext) as UIContextType;

    useEffect(() => {
        setFrom(Office.context.mailbox.item.from.displayName);
        setItems([...initFeedback]);
    },[itemChanged]);


    function sendFeedback(value) {
        const userClient = new UserClient();
        const feedbackData = {message: Office.context.mailbox.item.itemId ,score: value}
        userClient.sendFeedback(feedbackData).then(() => {
            let newFeedback = [...initFeedback]
            newFeedback[value-1] = {value:value,selected:true};
            setItems([...newFeedback]);
        })
    }

    const onRenderCell = React.useCallback((item) => {
        return (

                <div
                    className={classNames.listGridExampleTile}
                    data-is-focusable
                    style={{
                        width: 100 / 6 + '%',
                    }}
                >
                    <div className={classNames.listGridExampleSizer}>
                        <div className={classNames.listGridExamplePadder}>
                            <span>{item.value}</span>
                            {item.selected &&
                                <PrimaryButton
                                    text={item.value}
                                    className={classNames.listGridExampleLabel}
                                    onClick={() => {
                                        sendFeedback(item.value)
                                    }}
                                />
                            }
                            {!item.selected &&
                            <DefaultButton
                                text={item.value}
                                className={classNames.listGridExampleLabel}
                                onClick={() => {
                                    sendFeedback(item.value)
                                }}
                            />
                            }
                        </div>
                    </div>
                </div>

        );
    }, []);

    const stackTokens: IStackTokens = { childrenGap: 10 };

    return (

        <main className="ms-welcome__main">
        <section className="ms-font-m ms-fontColor-neutralPrimary">

                <h2 className="ms-font-xl ms-fontColor-themePrimary ms-textAlignLeft"
                    id="question_1">{t('feedbackForm.feedback_title')}</h2>

            <Stack  tokens={stackTokens}>

            <Text block variant="medium">{t('feedbackForm.feedback_question')}</Text>
                <FocusZone>
                <List
                    className={classNames.listGridExample}
                    items={items}
                    renderedWindowsAhead={4}
                    onRenderCell={onRenderCell}
                />
                </FocusZone>

            <Text block variant="medium">{t('feedbackForm.feedback_subtitle',{'from': from})}</Text>
            </Stack>




        </section>
        </main>

    );
}
export default Feedback;
