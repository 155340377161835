import * as React from "react";
import {Trans, useTranslation} from "react-i18next";
import {
    Callout,
    Link,
    mergeStyleSets,
    Text,
    FontWeights,
    Checkbox,
    DefaultButton,
    Label,
    TextField, DatePicker, DayOfWeek, defaultDatePickerStrings
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { Stack, IStackTokens } from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import {useEffect, useState} from "react";
import {UIContext} from '../../helpers/uiContext';
import {buttonStyles, stackButtonTokens, stackItemStyles} from "./Footer";

const EmailWizard = () =>{

    const {t} = useTranslation();
    const [cb1, setCb1] = useState(false);
    const [cb2, setCb2] = useState(false);
    const [cb3, setCb3] = useState(false);
    const [cb4, setCb4] = useState(false);
    const [cb5, setCb5] = useState(false);
    const [cb6, setCb6] = useState(false);
    const [otherReason, setOtherReason] = useState('');
    const [currentQuestion,setCurrentQuestion] = useState(1);
    const [selectedDate, setSelectedDate] = useState<Date | undefined>();;

    useEffect(() => {
        let title_prefix = "";

        if (cb1 || cb2 || cb3) {
            title_prefix += "[" + t('emailChecker.actions_act') + "]";
        }
        if (cb4) {
            title_prefix += "[" + t('emailChecker.actions_validate') + "]";
        }
        if (cb5) {
            title_prefix += "[" + t('emailChecker.actions_read') + "]";
        }
        if (cb6) {
            title_prefix += "[" + otherReason.toUpperCase() + "]";
        }

        if (selectedDate) {
            title_prefix += "[" + t('emailChecker.actions_before') + " "+ selectedDate.toLocaleDateString() + "]";
        }

        Office.context.mailbox.item.subject.getAsync(result => {
            if (result.status !== Office.AsyncResultStatus.Succeeded) {
                console.error(`Action failed with message ${result.error.message}`);
                return;
            }

            let title = result.value;
            if (title.indexOf(":")) {
                title = title.substring(title.indexOf(":") + 1, title.length);
            }

            if (title_prefix && title_prefix.length > 0) {
                title = `${title_prefix}:${title}`;
            }

            Office.context.mailbox.item.subject.setAsync(`${title}`, subject => {
                if (subject.status !== Office.AsyncResultStatus.Succeeded) {
                    console.error(`Action failed with message ${subject.error.message}`);
                    return;
                }
            });
        });

    }, [cb1,cb2,cb3,cb4,cb5,cb6,otherReason,selectedDate]);



    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const labelId = useId('callout-label');
    const descriptionId = useId('callout-description');
    const stackTokens: IStackTokens = { childrenGap: 10 };
    const numericalSpacingStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    return (

        <Stack horizontalAlign="baseline" tokens={stackTokens}>
                {currentQuestion === 1 &&
                <>
                    <h2 className="ms-font-xl ms-fontColor-themePrimary ms-textAlignLeft"
                          id="question_1">{t('emailChecker.question_1')}</h2>

                    <Stack horizontal tokens={stackTokens}>
                        <PrimaryButton
                            id={'question_1_no_button'}
                            onClick={toggleIsCalloutVisible}
                            text={t('common.no')}
                            className={styles.button}
                        />

                        {isCalloutVisible && (
                            <Callout
                                className={styles.callout}
                                ariaLabelledBy={labelId}
                                ariaDescribedBy={descriptionId}
                                gapSpace={0}
                                target={`#${'question_1_no_button'}`}
                                onDismiss={toggleIsCalloutVisible}
                                setInitialFocus
                            >
                                <Text block variant="xLarge" className={styles.title} id={labelId}>
                                    {t('emailChecker.question_1_no_title')}
                                </Text>
                                <Text block variant="small" id={descriptionId}>
                                    {t('emailChecker.question_1_no_body')}
                                </Text>
                                <Link href="https://app.workbettertogether.ch/help" target="_blank"
                                      className={styles.link}>
                                    {t('emailChecker.question_1_no_more')}
                                </Link>
                            </Callout>
                        )}


                        <PrimaryButton
                            id={'question_1_yes_button'}
                            text={t('common.yes')}
                            className={styles.button}
                            onClick={() => {setCurrentQuestion(2)}}
                        />

                    </Stack>
                </>
                }
                {currentQuestion === 2 &&
                    <>
                    <h2 className="ms-font-xl ms-fontColor-themePrimary ms-textAlignLeft"
                          id="question_2">{t('emailChecker.question_2')}</h2>


                        <Stack tokens={numericalSpacingStackTokens}>
                            <Checkbox checked={cb1} id="cb1" name="cb1" label={t('emailChecker.question_2_answer_1')}
                                      onChange={e => setCb1((e.target as HTMLInputElement).checked)}/>
                            <Checkbox id="cb-2" name="cb-2" label={t('emailChecker.question_2_answer_2')}
                                          onChange={e => setCb2((e.target as HTMLInputElement).checked)}/>
                            <Checkbox id="cb-3" name="cb-3" label={t('emailChecker.question_2_answer_3')}
                                          onChange={e => setCb3((e.target as HTMLInputElement).checked)}/>
                            <Checkbox id="cb-4" name="cb-4" label={t('emailChecker.question_2_answer_4')}
                                          onChange={e => setCb4((e.target as HTMLInputElement).checked)}/>
                            <Checkbox id="cb-5" name="cb-5" label={t('emailChecker.question_2_answer_5')}
                                          onChange={e => setCb5((e.target as HTMLInputElement).checked)}/>
                            <Checkbox id="cb-6" name="cb-6" label={t('emailChecker.question_2_answer_6')}
                                          onChange={e => setCb6((e.target as HTMLInputElement).checked)}/>


                            {cb6 &&
                                <>
                                    <Label htmlFor={'other_reason_input'}>{t('emailChecker.question_2_answer_6_label')}</Label>
                                    <TextField id={'other_reason_input'} onChange={e => setOtherReason((e.target as HTMLInputElement).value)}/>
                                </>
                            }

                        </Stack>

                    <Stack horizontal tokens={stackTokens}>
                        <DefaultButton
                            id={'question_2_back_button'}
                            text={t('common.back')}
                            className={styles.button}
                            onClick={() => {setCurrentQuestion(1)}}
                        />
                        <PrimaryButton
                            id={'question_2_next_button'}
                            text={t('common.next')}
                            className={styles.button}
                            onClick={() => {setCurrentQuestion(3)}}
                        />

                    </Stack>
                    </>
                }
            {currentQuestion === 3 &&
            <>
                <h2 className="ms-font-xl ms-fontColor-themePrimary ms-textAlignLeft"
                    id="question_3">{t('emailChecker.question_3')}</h2>

                <Stack horizontal tokens={stackTokens} className={styles.datePicker}>
                    <DatePicker
                        id="calendar-date-input"
                        value={selectedDate}
                        onSelectDate={setSelectedDate as (date: Date | null | undefined) => void}
                        firstDayOfWeek={DayOfWeek.Monday}
                        placeholder={t('common.calendar_date_input')}
                        ariaLabel={t('common.calendar_date_input')}
                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                        strings={defaultDatePickerStrings}
                        className={styles.datePicker}
                    />


                </Stack>

                <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton
                        id={'question_3_no_deadline'}
                        text={t('emailChecker.no_deadline')}
                        className={styles.button}
                        onClick={() => {setSelectedDate(null);setCurrentQuestion(4)}}
                    />
                </Stack>

                <Stack horizontal tokens={stackTokens}>

                    <DefaultButton
                        id={'question_2_back_button'}
                        text={t('common.back')}
                        className={styles.button}
                        onClick={() => {setCurrentQuestion(2)}}
                    />
                    <PrimaryButton
                        id={'question_2_next_button'}
                        text={t('common.next')}
                        className={styles.button}
                        onClick={() => {setCurrentQuestion(4)}}
                    />

                </Stack>
            </>
            }
            {currentQuestion === 4 &&
            <>
                <h2 className="ms-font-xl ms-fontColor-themePrimary ms-textAlignLeft"
                    id="question_4">{t('emailChecker.question_4')}</h2>

                <h3 className="ms-font-l ms-fontColor-neutralSecondary" id="question_4_subtitle">
                    <Trans
                        i18nKey="emailChecker.question_4_subtitle"></Trans>

                </h3>

                <Stack horizontal tokens={stackTokens}>

                    <DefaultButton
                        id={'question_2_back_button'}
                        text={t('common.back')}
                        className={styles.button}
                        onClick={() => {setCurrentQuestion(3)}}
                    />
                    <UIContext.Consumer>
                        {({toggleEmailWizard}) => (
                            <Stack>
                                <Stack.Item grow >
                                    <PrimaryButton  text={t('main.button_main_menu')} onClick={toggleEmailWizard}/>
                                </Stack.Item>
                            </Stack>
                        )}
                    </UIContext.Consumer>

                </Stack>

            </>
            }


            <div id="recipient-check">
            </div>

            <div id="sentiment-check">
            </div>

            <div id="sentence-check">
            </div>

            <div id="readability-check">
            </div>
        </Stack>
    );
};
export default EmailWizard;

const styles = mergeStyleSets({
    button: {
        width: 130,
    },
    callout: {
        width: 320,
        maxWidth: '90%',
        padding: '20px 24px',
    },
    title: {
        marginBottom: 12,
        fontWeight: FontWeights.semilight,
    },
    link: {
        display: 'block',
        marginTop: 20,
    },
    datePicker: {
        width: '100%'
    }
});
