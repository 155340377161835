class Config {
    ENDPOINT = "https://wbt-api.azurewebsites.net/";
    MESSAGE_URL = `${this.ENDPOINT}api/v1/messages`;
    LANG_URL = `${this.ENDPOINT}api/v1/messages/lang`;
    SENTIMENT_URL = `${this.ENDPOINT}api/v1/messages/sentiment`;
    FEEDBACK_URL = `${this.ENDPOINT}api/v1/messages/feedback`;
    TOKEN_URL = `${this.ENDPOINT}api/v1/auth/token`;
    USER_URL = `${this.ENDPOINT}api/v1/users`;
    ACCESS_TOKEN = "accessToken";
    EXPIRATION = "expiration";

    defaultHeaders() {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
        };
    }

    getExpiration(token) {
        let encodedPayload = token ? token.split(".")[1] : null;
        if (encodedPayload) {
            encodedPayload = encodedPayload.replace(/-/g, "+").replace(/_/g, "/");
            const payload = JSON.parse(window.atob(encodedPayload));
            return payload?.exp ? payload?.exp * 1000 : 0;
        }
        return 0;
    }
}

export default Config;
