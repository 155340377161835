import {getAllRecipients, getMessageText, removeFeedbackForm} from "./Message";
import UserClient from "./UserClient";
import i18next from "i18next";

function checkAttachments() {

    let options = { asyncContext: { currentItem: Office.context.mailbox.item } };

    return new Promise(function (resolve, reject) {
        try {
            let hasExternalEmail = false;
            Office.context.mailbox.item.from.getAsync({},function (asyncResult) {
                let emailAddress = asyncResult.value.emailAddress;
                let domain = emailAddress.substring(emailAddress.lastIndexOf("@") + 1);

                getAllRecipients().then(recipients => {
                    for (let i = 0; i < recipients.length; i++) {
                        let recipientDomain = recipients[i].emailAddress.substring(
                            recipients[i].emailAddress.lastIndexOf("@") + 1
                        );
                        if (recipientDomain != domain) {
                            hasExternalEmail = true;
                            break;
                        }
                    }

                    Office.context.mailbox.item.getAttachmentsAsync(options, function (asyncResult){
                        if (asyncResult.value.length > 0 && !hasExternalEmail) {
                            const details = {
                                type: "errorMessage",
                                message: i18next.t('checker_no_external_recipient')
                            };
                            Office.context.mailbox.item.notificationMessages.addAsync("attachment", details);
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
                })
            })
        }
        catch (error) {
            reject(error);
        }
    });
}

function checkSentiment() {

    return new Promise(function (resolve, reject) {
        try {
            Office.context.mailbox.item.body.getAsync("html", { }, (result) => {

                let cleanedText = getMessageText(removeFeedbackForm(result.value));

                if (!cleanedText || cleanedText.length < 3) {
                    let sentimentValue = {sentences: 0};
                    resolve(sentimentValue);
                } else {
                    const userClient = new UserClient();
                    userClient.getSentiment(cleanedText).then(sentiment => {
                        resolve(sentiment.data);
                    })
                }
            });
        }
        catch (error) {
                reject(error);
            }
    });
}

export function checkEmail(){

    return new Promise(function (resolve, reject) {
        try {
            Office.context.mailbox.item.notificationMessages.replaceAsync("email_checker_progress", {
                type: Office.MailboxEnums.ItemNotificationMessageType.ProgressIndicator,
                message: i18next.t('emailChecker.checking_email', {'status': i18next.t('emailChecker.check_attachment')})
            });

            Office.context.mailbox.item.notificationMessages.getAllAsync(function(asyncResult) {
                if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                    asyncResult.value.forEach(key => {
                        try{
                            if (key.type != Office.MailboxEnums.ItemNotificationMessageType.ProgressIndicator) {
                                Office.context.mailbox.item.notificationMessages.removeAsync(key.key);
                            }
                        }
                        catch (e) {

                        }

                    });


                    let hasError = false;
                    checkAttachments().then(error => {
                        if(error){
                            hasError = true;
                        }

                        Office.context.mailbox.item.notificationMessages.replaceAsync("email_checker_progress", {
                            type: Office.MailboxEnums.ItemNotificationMessageType.ProgressIndicator,
                            message: i18next.t('emailChecker.checking_email', {'status': i18next.t('emailChecker.check_sentiment')})
                        });

                        checkSentiment().then(message => {
                            if(message.sentences < 2){
                                Office.context.mailbox.item.notificationMessages.addAsync("sentences", {
                                    type: Office.MailboxEnums.ItemNotificationMessageType.ErrorMessage,
                                    message: i18next.t('emailChecker.checker_too_short',{'value': message.sentences})
                                });
                                hasError = true;
                            }

                            if(message.polarity < -0.2){
                                Office.context.mailbox.item.notificationMessages.addAsync("polarity", {
                                    type: Office.MailboxEnums.ItemNotificationMessageType.ErrorMessage,
                                    message: i18next.t('emailChecker.checker_too_negative',{'value': Math.round(message.polarity*100)/100})
                                });
                                hasError = true;
                            }

                            Office.context.mailbox.item.notificationMessages.replaceAsync("lix_score", {
                                type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
                                message: i18next.t('emailChecker.checker_lix_score', {'value': Math.round(message.lix_score)}),
                                icon: "Icon.80x80",
                                persistent: true,
                            });

                            Office.context.mailbox.item.notificationMessages.replaceAsync("fk_score", {
                                type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
                                message: i18next.t('emailChecker.checker_fk_grade_level', {'value': Math.round(message.fk_grade_level)}),
                                icon: "Icon.80x80",
                                persistent: true,
                            });

                            Office.context.mailbox.item.notificationMessages.replaceAsync("smog_score", {
                                type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
                                message: i18next.t('emailChecker.checker_grade_level', {'value': Math.round(message.smog_score)}),
                                icon: "Icon.80x80",
                                persistent: true,
                            });

                            if(!hasError){
                                Office.context.mailbox.item.notificationMessages.replaceAsync("general", {
                                    type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
                                    message: i18next.t('emailChecker.no_error'),
                                    icon: "Icon.80x80",
                                    persistent: true,
                                });
                            }

                            Office.context.mailbox.item.notificationMessages.removeAsync("email_checker_progress");
                            resolve(hasError);
                        });
                    });
                }
            });
        }
        catch (error) {
            reject(error);
        }
    });
}
