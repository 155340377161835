import App from "./components/App";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { ThemeProvider } from "@fluentui/react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import "../i18n";
import jwtDecode from "jwt-decode";
import {getMessageText, removeFeedbackForm} from "../api/Message";
import UserClient from "../api/UserClient";
import {getUserIdentityTokenAsync} from "../api/Fetch";

/* global document, Office, module, require */

initializeIcons();

let isOfficeInitialized = false;
let retryGetAccessToken = 0;
let error = null;

const title = "WBT Compose Task Pane Add-in";

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <ThemeProvider>
        <Component title={title} isOfficeInitialized={isOfficeInitialized} error={error} />
      </ThemeProvider>
    </AppContainer>,
    document.getElementById("container")
  );
};


async function getGraphData() {
  try {
    // The /api/DoSomething controller will make the token exchange and use the
    // access token it gets back to make the call to MS Graph.
    let exchangeResponse;

    try{
      exchangeResponse = await OfficeRuntime.auth.getAccessToken({ allowConsentPrompt: true, allowSignInPrompt: true });
    }
    catch (e){
      exchangeResponse = await getUserIdentityTokenAsync();
    }

    let userToken = jwtDecode(exchangeResponse);

    if (userToken['claims']) {
      // Microsoft Graph requires an additional form of authentication. Have the Office host
      // get a new token using the Claims string, which tells AAD to prompt the user for all
      // required forms of authentication.
      let mfaBootstrapToken = await OfficeRuntime.auth.getAccessToken({
        authChallenge: userToken['claims'],
      });
      exchangeResponse = await getGraphToken(mfaBootstrapToken) as string;
      userToken = jwtDecode(exchangeResponse);
    }

    if (userToken['error']) {
      // AAD errors are returned to the client with HTTP code 200, so they do not trigger
      // the catch block below.
      handleAADErrors(userToken['error']);
    }


    console.log(userToken);
  }
  catch (exception) {
    if (exception.code) {
      handleClientSideErrors(exception);
    } else {
      showMessage("EXCEPTION: " + JSON.stringify(exception));
    }
  }
}

function showMessage(errorMsg: string) {
  error = errorMsg;
}

function handleClientSideErrors(error) {
  switch (error.code) {
    case 13001:
      // No one is signed into Office. If the add-in cannot be effectively used when no one
      // is logged into Office, then the first call of getAccessToken should pass the
      // `allowSignInPrompt: true` option. Since this sample does that, you should not see
      // this error.
      showMessage(
          "No one is signed into Office. But you can use many of the add-ins functions anyway. If you want to log in, press the Get OneDrive File Names button again."
      );
      break;
    case 13002:
      // The user aborted the consent prompt. If the add-in cannot be effectively used when consent
      // has not been granted, then the first call of getAccessToken should pass the `allowConsentPrompt: true` option.
      showMessage(
          "You can use many of the add-ins functions even though you have not granted consent. If you want to grant consent, press the Get OneDrive File Names button again."
      );
      break;
    case 13006:
      // Only seen in Office on the web.
      showMessage(
          "Office on the web is experiencing a problem. Please sign out of Office, close the browser, and then start again."
      );
      break;
    case 13008:
      // Only seen in Office on the web.
      showMessage(
          "Office is still working on the last operation. When it completes, try this operation again."
      );
      break;
    case 13010:
      // Only seen in Office on the web.
      showMessage(
          "Follow the instructions to change your browser's zone configuration."
      );
      break;
    default:
      // For all other errors, including 13000, 13003, 13005, 13007, 13012, and 50001, fall back
      // to non-SSO sign-in.
      //dialogFallback();
      break;
  }
}

function handleAADErrors(exchangeResponse) {
  // On rare occasions the bootstrap token is unexpired when Office validates it,
  // but expires by the time it is sent to AAD for exchange. AAD will respond
  // with "The provided value for the 'assertion' is not valid. The assertion has expired."
  // Retry the call of getAccessToken (no more than once). This time Office will return a
  // new unexpired bootstrap token.
  if (
      exchangeResponse.error_description.indexOf("AADSTS500133") !== -1 &&
      retryGetAccessToken <= 0
  ) {
    retryGetAccessToken++;
    getGraphData();
  } else {
    // For all other AAD errors, fallback to non-SSO sign-in.
    // For debugging:
    // showMessage("AAD ERROR: " + JSON.stringify(exchangeResponse));
    //dialogFallback();
  }
}

const getGraphToken = async(mfaBootstrapToken) => {
  const userClient = new UserClient();

  return new Promise(function (resolve, reject) {
    try {
      userClient.getToken(mfaBootstrapToken).then(token => {
        resolve(token);
      }, (error) => {
        reject(error)
      })
    }
    catch (error) {
      reject(error);
    }
  });


}

/* Render application after Office initializes */
Office.initialize = () => {
  isOfficeInitialized = true;
  getGraphData().then(() => render(App));
};


if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
