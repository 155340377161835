import * as React from "react";
import Header from "./Header";
import HeroList, { HeroListItem } from "./HeroList";
import Progress from "./Progress";
import MainMenu from "./MainMenu";
import EmailWizard from "./EmailWizard";
import {UIContext} from '../../helpers/uiContext';
import Message = Office.Item;
import Footer from "./Footer";
import {withTranslation, WithTranslation} from "react-i18next";
import Feedback from "./Feedback";


/* global require */

export interface AppProps extends WithTranslation{
  title: string;
  isOfficeInitialized: boolean;
  error: string;
}

export interface AppState{
  showEmailWizard: boolean;
  showMainMenu: boolean;
  toggleEmailWizard: () => {};
  listItems: HeroListItem[];
  mailboxItem: Message;
  messageObject: string;
  myLanguage: string;
  itemChanged: boolean;
}

class App extends React.Component<AppProps, AppState> {
  toggleEmailWizard: () => {};

  constructor(props, context) {
    super(props, context);

    Office.context.mailbox.addHandlerAsync(
        Office.EventType.ItemChanged,
        () => {
          this.setState(() => ({
            itemChanged: !this.state.itemChanged
          }))
        });

    // @ts-ignore
    this.toggleEmailWizard = () => {
      this.setState(state => ({
        showEmailWizard: !(state.showEmailWizard),
        showMainMenu: state.showEmailWizard,
      }));
    };

    // State also contains the updater function so it will
    // be passed down into the context provider
    // @ts-ignore
    this.state = {
      showEmailWizard: false,
      showMainMenu: true,
      toggleEmailWizard: this.toggleEmailWizard,
      listItems: [],
      mailboxItem: {},
      myLanguage: Office.context.displayLanguage,
      itemChanged: false
    };
  }

  componentDidMount() {

    this.setState({
      listItems: [
        {
          icon: "Ribbon",
          primaryText: "Achieve more with Office integration",
        },
        {
          icon: "Unlock",
          primaryText: "Unlock features and functionality",
        },
        {
          icon: "Design",
          primaryText: "Create and visualize like a pro",
        },
      ],
      mailboxItem: Office.context.mailbox.item,
    });

  }


  render() {
    const { title, isOfficeInitialized, error } = this.props;
    const { showEmailWizard, showMainMenu, mailboxItem, itemChanged } = this.state;

    if (process.env.NODE_ENV !== 'production') {
      console.log('Looks like we are in development mode!' + process.env.NODE_ENV);
    }

    if (!isOfficeInitialized) {
      return (
        <Progress
          title={title}
          logo={require("./../../../assets/logo-filled.png")}
          message="Please sideload your addin to see app body."
        />
      );
    }

    // @ts-ignore
    return (

        <UIContext.Provider value={this.state}>
          {error &&
              <span>error</span>
          }
          <div className="ms-welcome">

            {!showEmailWizard &&
                <>

                <Header logo={require("./../../../assets/logo-filled.png")} title={this.props.title} message={this.props.t('main.greeting')} />

                  {!Office.context.mailbox.item.displayReplyForm &&
                  <MainMenu/>
                  }
                  {Office.context.mailbox.item.displayReplyForm &&
                    <Feedback/>
                  }
                </>
            }


            <main className="ms-welcome__main">
              <section className="ms-font-m ms-fontColor-neutralPrimary">
                {showEmailWizard && <EmailWizard></EmailWizard>
                }
              </section>
            </main>



            <Footer/>


          </div>
        </UIContext.Provider>
    );
  }
}
export default withTranslation()(App);
