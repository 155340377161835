
export function getConfig() {
    let config = {};
    config['workBetterUrl'] = Office.context.roamingSettings.get("workBetterUrl");
    config['workBetterToken'] = Office.context.roamingSettings.get("workBetterToken");
    config['workBetterUsername'] = Office.context.roamingSettings.get("workBetterUsername");
    return config;
}

export function setConfig(config, callback) {
    Office.context.roamingSettings.set("workBetterUrl", config.workBetterUrl);
    Office.context.roamingSettings.set("workBetterToken", config.workBetterToken);
    Office.context.roamingSettings.set("workBetterUsername", config.workBetterUsername);
    Office.context.roamingSettings.saveAsync(callback);
}
