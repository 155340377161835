import Config from "./Config";
import {callApiWithToken, tokenApiWithToken, postApiPlainWithToken, postApiWithToken} from "./Fetch";
import jwtDecode from "jwt-decode";

class UserClient {

    constructor() {
        this.config = new Config();
    }

    async getToken(boostrapToken) {
        console.log("Get token");

        return tokenApiWithToken(boostrapToken, this.config.TOKEN_URL)
            .then(([response, json]) => {
                console.log("Response JSON: " + JSON.stringify(json));
                if (!response.ok) {
                    return { success: false, error: json };
                }
                return { success: true, data: json };
            })
            .catch((e) => {
                this.handleError(e);
            });
    }

    async createMessage(messageData) {
        console.log("Create message");

        return postApiWithToken(this.config.MESSAGE_URL, messageData, "POST")
            .then(([response, json]) => {
                console.log("Response JSON: " + JSON.stringify(json));
                if (!response.ok) {
                    return { success: false, error: json };
                }
                return { success: true, data: json };
            })
            .catch((e) => {
                this.handleError(e);
            });
    }

    async getSentiment(body) {
        console.log("Get message sentiment");
        const messageData = { body: body };

        return postApiWithToken(this.config.SENTIMENT_URL, messageData, "POST")
            .then(([response, json]) => {
                console.log("Response JSON: " + JSON.stringify(json));
                if (!response.ok) {
                    return { success: false, error: json };
                }
                return { success: true, data: json };
            })
            .catch((e) => {
                this.handleError(e);
            });
    }

    async detectLanguage(body) {
        console.log("Update message");

        return postApiPlainWithToken(this.config.LANG_URL, body, "POST")
            .then(([response, lang]) => {
                console.log("Response : " + lang);
                if (!response.ok) {
                    return { success: false, error: lang };
                }
                return { success: true, data: lang };
            })
            .catch((e) => {
                this.handleError(e);
            });
    }

    async sendFeedback(feedbackData) {
        console.log("Send feedback");

        return postApiWithToken(this.config.FEEDBACK_URL, feedbackData, "POST")
            .then(([response, json]) => {
                console.log("Response JSON: " + JSON.stringify(json));
                if (!response.ok) {
                    return { success: false, error: json };
                }
                return { success: true, data: json };
            })
            .catch((e) => {
                this.handleError(e);
            });
    }

    async updateMessage(messageData) {
        console.log("Update message");

        return postApiWithToken(this.config.MESSAGE_URL, messageData, "PUT")
            .then(([response, json]) => {
                console.log("Response JSON: " + JSON.stringify(json));
                if (!response.ok) {
                    return { success: false, error: json };
                }
                return { success: true, data: json };
            })
            .catch((e) => {
                this.handleError(e);
            });
    }


    handleError(error) {
        const err = new Map([
            [TypeError, "There was a problem fetching the response."],
            [SyntaxError, "There was a problem parsing the response."],
            [Error, error.message],
        ]).get(error.constructor);
        console.log(err);
        return err;
    }
}

export default UserClient;
