export default {
    name: 'WBTLanguageDetector',

    lookup(_options) {
        // options -> are passed in options
        return Office.context.displayLanguage;
    },

    cacheUserLanguage(_lng, _options) {
        // options -> are passed in options
        // lng -> current language, will be called after init and on changeLanguage
        // store it
        localStorage.setItem('i18nextLng', Office.context.displayLanguage)
    },
};
