import * as React from "react";
import {Trans, useTranslation} from "react-i18next";
import * as settings from "../../helpers/addinConfig"
import {UIContext} from '../../helpers/uiContext';
import {IStackTokens, PrimaryButton, Stack, Text} from "@fluentui/react";
import {buttonStyles, stackButtonTokens, stackItemStyles} from "./Footer";

const stackTokens: IStackTokens = { childrenGap: 10 };

const MainMenu = (props) => {

  const { children } = props;
  const {t,i18n} = useTranslation();

  const config = settings.getConfig();

  return (
      <UIContext.Consumer>
          {({toggleEmailWizard}) => (
              <main className="ms-welcome__main">
                <section className="ms-font-m ms-fontColor-neutralPrimary">
                <h2 className="ms-font-xl ms-fontColor-themePrimary ms-textAlignLeft"
                    id="question_1">{t('main.wizard_title')}</h2>

                  <Stack tokens={stackTokens}>
                    <Text block variant="mediumPlus">{t('main.wizard_message')}</Text>
                    <Stack.Item grow styles={stackItemStyles}>
                    <PrimaryButton styles={buttonStyles} text={t('main.button_wizard')} onClick={toggleEmailWizard}/>
                    </Stack.Item>
                  </Stack>
                </section>
            </main>

          )}
      </UIContext.Consumer>

    );
}
export default MainMenu;
