    import UserClient from "./UserClient";

    export function getAllRecipients() {
        return new Promise(function (resolve, reject) {
            try {
                Office.context.mailbox.item.to.getAsync(asyncResult => {
                    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                        let recipients = asyncResult.value;

                        Office.context.mailbox.item.cc.getAsync(asyncResult => {
                            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                                recipients = [...recipients,...asyncResult.value];

                                Office.context.mailbox.item.bcc.getAsync(asyncResult => {
                                    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                                        recipients = [...recipients,...asyncResult.value];
                                        resolve(recipients)
                                    }
                                });
                            }
                        });
                    }
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    export function getFeedbackForm(messageId, config, t, i18n) {
        return new Promise(function (resolve, reject) {
            try {
                Office.context.mailbox.item.from.getAsync(asyncResult => {
                    let html2 = "";
                    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                        const msgFrom = asyncResult.value;

                        let savedLang = i18n.language;
                        getBodyLang().then(lang => {
                            i18n.changeLanguage(lang).then(r => {
                                    //html2 = '';
                                    html2 =
                                        `<div id="wbt-main" style="font-family:"Open Sans", Helvetica, Arial, sans-serif;color:#555;max-width:680px;margin:0 auto;padding:0 20px">` +
                                        `<div style="text-align:center;padding:20px 0;position:relative;margin-bottom:10px;font-size: initial"><h2><img src="https://app.wbt.team/icon-32.png" style="vertical-align: bottom;"/> ${t('feedbackForm.feedback_title')}</h2><p>${t('feedbackForm.feedback_subtitle',{'from':msgFrom.displayName})}</p><p>${t('feedbackForm.feedback_question')}</p></div>` +
                                        `<div style="text-align:center;font-size: initial; font-weight: 700;"> <ul id="stars" style="list-style-type:none;padding:0;-moz-user-select:none;-webkit-user-select:none;">` +
                                        `<li style="display:inline-block;cursor: pointer;margin-right: 10px;" data-value="1"><a href="${config.workBetterUrl}/feedback?message=${messageId}&score=1" target="_blank" rel="noopener noreferrer" style="font-size:2.5em;color:#ccc;text-decoration:None">1</a></li>` +
                                        `<li style="display:inline-block;cursor: pointer;margin-right: 10px; margin-left: 10px;" data-value="2"><a href="${config.workBetterUrl}/feedback?message=${messageId}&score=2" target="_blank" rel="noopener noreferrer" style="font-size:2.5em;color:#ccc;text-decoration:None;cursor: pointer;">2</a></li>` +
                                        `<li style="display:inline-block;cursor: pointer;margin-right: 10px; margin-left: 10px;" data-value="3"><a href="${config.workBetterUrl}/feedback?message=${messageId}&score=3" target="_blank" rel="noopener noreferrer" style="font-size:2.5em;color:#ccc;text-decoration:None;cursor: pointer;">3</a></li>` +
                                        `<li style="display:inline-block;cursor: pointer;margin-right: 10px; margin-left: 10px;" data-value="4"><a href="${config.workBetterUrl}/feedback?message=${messageId}&score=4" target="_blank" rel="noopener noreferrer" style="font-size:2.5em;color:#ccc;text-decoration:None;cursor: pointer;">4</a></li>` +
                                        `<li style="display:inline-block;cursor: pointer;margin-right: 10px; margin-left: 10px;" data-value="5"><a href="${config.workBetterUrl}/feedback?message=${messageId}&score=5" target="_blank" rel="noopener noreferrer" style="font-size:2.5em;color:#ccc;text-decoration:None;cursor: pointer;">5</a></li>` +
                                        `<li style="display:inline-block;cursor: pointer;margin-left: 10px;" data-value="6"><a href="${config.workBetterUrl}/feedback?message=${messageId}&score=6" target="_blank" rel="noopener noreferrer" style="font-size:2.5em;color:#ccc;text-decoration:None;cursor: pointer;">6</a></li>` +
                                        `</ul></div></div>`;
                                    i18n.changeLanguage(savedLang).then(r =>
                                        resolve(html2)
                                    )
                                    ;
                                }
                            );
                        });
                    } else {
                        reject(asyncResult.error);
                    }
                });
            }
            catch (error) {
                reject(error);
            }
        })
    }

     function getBodyLang() {
        const userClient = new UserClient();

        return new Promise(function (resolve, reject) {
            try {
                Office.context.mailbox.item.body.getAsync("text", {asyncContext: "This is passed to the callback"}, function (
                    result
                ) {
                    if (result.value && result.value.length >= 3) {

                        userClient.detectLanguage(result.value).then(lang => {
                            resolve(lang.data);
                        })

                    } else {
                        resolve("en");
                    }
                });
            } catch (error) {
                reject(error);
            }
        });

    }

    export function checkIfFormInserted(){
        return new Promise(function (resolve, reject) {
            try {
                Office.context.mailbox.item.body.getAsync("html", {},function (asyncResult) {
                    resolve(asyncResult.value.includes('x_wbt-main'));
                });
            }
            catch (error) {
                reject(error);
            }
        })
    }

    export function removeFeedbackForm(htmlString){
        let parser = new DOMParser();
        let htmlDoc = parser.parseFromString(htmlString, 'text/html');
        if(htmlDoc.getElementById("x_wbt-main")) {
            htmlDoc.getElementById("x_wbt-main").remove();
        }
        return htmlDoc.body.innerHTML;
    }

    export function getMessageText(htmlString){
        let parser = new DOMParser();
        let htmlDoc = parser.parseFromString(htmlString, 'text/html');
        const emailBody = htmlDoc.body.textContent;

        return emailBody;

    }
